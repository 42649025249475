import { AxiosRequestConfig } from 'axios';

import axios from './axios';
import { DEV_BASE_URL } from '../constants';

export const globalGetService = <T = any>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
) => {
  return axios.get<T>(url, {
    ...config,
    params: data,
  });
};

export const globalGetServiceV2 = <T = any>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
) => {
  return axios.get<T>(url, {
    ...config,
    baseURL: DEV_BASE_URL.replace('api/v1', 'api/v2'),
    params: data,
  });
};

export const globalDeleteService = <T = any>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
) => {
  return axios.delete<T>(url, {
    ...config,
    data,
  });
};

export const globalPostService = <T = any>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
) => {
  return axios.post<T>(url, data, config);
};

export const globalPutService = <T = any>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
) => {
  return axios.put<T>(url, data, config);
};
